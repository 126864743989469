import React, { useEffect, lazy, Suspense } from "react";
import axios from "axios";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import TagManager from 'react-gtm-module';

// styles for this kit
import "./assets/css/bootstrap.min.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./assets/scss/now-ui-kit.scss";
import "./assets/demo/demo.css";
import "./assets/demo/nucleo-icons-page-styles.css";
import "./assets/css/style.css";
import "./assets/css/Loader.css";

const Routes = lazy(() => import('router/Routers.js'));
const MainNavbar = lazy(() => import('components/Navbars/MainNavbar/MainNavbar'));
const Footer = lazy(() => import('components/Footers/Footer.js'));
 
const tagManagerArgs = {
  gtmId: 'GTM-NGMP9XHS',
  dataLayer: {
    conversionID: '876744057', // Replace with your Conversion ID
    conversionLabel: '8vWrCNDV8KsZEPmaiKID' // Replace with your Conversion Label
  }
}
 
TagManager.initialize(tagManagerArgs)

export default function App(){
  const { REACT_APP_NEXT_PUBLIC_SERVER_ENDPOINT } = process.env;
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect( async () => {
    try{
      await axios.get(
        `${REACT_APP_NEXT_PUBLIC_SERVER_ENDPOINT}/api/me`,
        { withCredentials: true }
      )
      sessionStorage.setItem('ciuli', JSON.stringify(true));
    } catch(e){
      sessionStorage.setItem('ciuli', JSON.stringify(false));
      console.log('Show me router error 1.0 =>', e)
    }
  });

  useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  return(
    <BrowserRouter>
      <Suspense fallback={<div className="lds-hourglass"></div>}>
        <MainNavbar />
        <div className="wrapper">
          <Routes />
          <Footer />
        </div>
      </Suspense>
    </BrowserRouter>
  );
}

// ReactDOM.render(
//   <App />,
//   document.getElementById("root")
// );

// if (typeof window !== 'undefined') {
//   ReactDOM.hydrate(
//     <App />,
//     document.getElementById("root")
//   );
// }

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  ReactDOM.hydrate(<App />, rootElement);
} else {
  ReactDOM.render(<App />, rootElement);
}